@import './../../variables.scss';

.ProductItem {
    border: 2px solid #eaeaea;
    margin-bottom: 30px;

    &.productInOferta {
        border: 2px solid green;
    }

    .ImgContainer {
        background-color: white;
        text-align: center;
        min-height: 175px;
        max-height: 175px;

        img { 
            height: 175px; 
            &.notAvailable { width: 100%; }
        }
    }

    .ContentContainer {
        background-color: $tertiary-light;
        padding: 15px;
        min-height: 185px;

        .Desart {
            color: $tertiary;
            font-size: 14px;
        }
    
        .Categorias { 
            color: $primary; 
            font-weight: 600;
            font-size: 13px;
            margin-bottom: 10px;
        }

        .Desart, .Categorias {
            min-height: 30px;
            max-height: 30px;
        }

        .Price {
            font-size: 20px;
            font-family: 'Oswald', sans-serif;
            font-weight: 500;
            color: $tertiary;
            margin-bottom: 5px;
        }

        .SinStock {
            background-color: $tertiary;
            padding: 5px;
            color: white;
            font-family: 'Oswald', sans-serif;
            font-weight: 400;
            text-align: center;
            font-size: 18px;
        }
    }
}


@media (max-width: 768px) {
    .ProductItem .ImgContainer img.notAvailable { width: auto; }
}

@media (max-width: 600px) {
    .ProductItem {
        .ContentContainer {
            min-height: auto;
            .Desart, .Categorias {
                min-height: auto;
                max-height: auto;
            }
        }
    }
}
