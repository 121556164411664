@import './../../variables.scss';
.Contacto {
    input,
    textarea {
        background-color: $primary-light;
        border-radius: 0;
        border: none;
        margin: 15px 0;
        padding: 10px;
        color: $tertiary;
    }

    textarea {
        height: 200px;
    }

    .container {
        h3 {
            margin-top:40px;
            margin-bottom: 20px;
        }
        .ContactoContent {
            margin: 40px auto 60px auto;
        }
      
        a { 
            color: $primary;
            font-weight: bold;
        }
        .SendBtnContainer {
            margin-top: 20px;
            text-align: right;
        }
    }
}