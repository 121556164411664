@import './../../variables.scss';
.Checkout {
    margin-bottom: 70px;
    
    .FbAclaration { 
        color: blue;
        font-weight: bold;
    }

    .FbForm {
        width: 50%;
        float: left;
        input { 
            border: 2px solid blue; 
            width: 100%
        }
    }

    .Completar { 
        width: 50%;
        float: left;
        padding: 40px;
        text-align: center;
        color: blue;
        font-weight: bold;
        font-size: 20px;
    }
    .CartTotal {
        font-family: 'Oswald', sans-serif;
        color: $tertiary;
        font-size: 21px;
        text-align: right;
        margin: 20px 0;
    }

    .Subtotal {
        font-family: 'Oswald', sans-serif;
        color: #a5a5a5;
        font-size: 18px;
        text-align: right;
        margin: 20px 0;
    }

    .Observaciones {
        height: 150px;
        padding: 10px 15px;
        margin-bottom: 15px;
        width: 100%;
        border-bottom: 1px solid $tertiary;
        background-color: #d0e6e6;
        color: $tertiary;
        font-weight: bold;
        border-radius: 20px;
    }
    .SeguirComprandoBtn,
    .TomarPedidoBtn {
        margin-bottom: 20px;
    }

    .TomarPedidoBtn {
        color: $tertiary;
    }
    
    .SeguirComprandoBtn {
        background-color: $tertiary;
        margin-right: 5px;
    }

    .NoDiscount,
    .SubtotalDetails {
        text-align: right;
        font-family: 'Oswald', sans-serif;
        font-weight: 500;
        font-size: 18px;
        color: $success;
    }

    .NoDiscount { color: $tertiary; }

    hr { border: 2px solid $primary; }

    .form-group {
        input {
            margin: 20px 0;
            width: 50%;
        }
    }
}