@import './../../variables.scss';
.Slider {
    h1 { 
        color: white; 
        font-family: 'Oswald', sans-serif;
        font-size: 21px;
        font-weight: 400;
    }
    .carousel-caption {
        left: 15%;
        top: 50% !important;
        transform: translateY(-50%);
        text-align: left;
        background-color: $primary;
        font-size: 24px;
        padding: 30px;
        min-height: 160px;
        max-height: 160px;
        width: 350px;
        
        p { 
            margin: 10px 0; 
            font-size: 13px;
        }

        button {
            background-color: red;
            color: white;
        }
    }
}

@media(max-width: 580px) {
    .Slider {
        h1 {
            font-size: 14px;

            &.CompraMensual { margin-top: 15px; }
            &.Descubri { font-size: 13px; }
        }

        .carousel-caption {
            padding: 20px;
            width: 180px;
        }
    }
}
