@import './../../variables.scss';

.AuthNavContainer {
    background-color: $tertiary;

    .subtitle {
        color: white;
        float: left;
        font-weight: 600;
        font-size: 14px;
        margin-top: 17px;
    }

    .container { overflow: hidden ;}
    ul.AuthNavLinks {
        margin: 10px 0;
        float: right;

        li {
            padding: 5px 20px 5px 0;

            a { 
                color: white;
                font-size: 13px;

                &.active-route { font-weight: 600; }
            }

            &:last-child { 
                border-left: 1px solid $secondary;
                padding: 5px 0 5px 20px;
            }
        }

        &.instructionsUl {
            float: left;

            li { 
                border-left: none!important;
            }
        }
    }
}
