@import './../../variables.scss';

.SearchContainer {
    position: relative;

    .SearchInput {
        border: none;
        border-bottom: 1px solid $tertiary;
        background-color: #d0e6e6;
        color: $tertiary;
        font-weight: bold;
        padding: 10px 55px;
        width: 100%;
        border-radius: 50px;
    }

    .searchIcon {
        background-repeat: no-repeat;
        background-image: url('./search.png');
        background-size: 25px;
        display: inline-block;
        height: 25px;
        width: 25px;
        position: absolute;
        left: 20px;
        bottom: 10px;
    }
}

@media (max-width: 9920px) {
    .SearchInput { margin-top: 20px; }
}