@import './../../variables.scss';

.WpBubble {
    position: fixed;
    right: 0;
    padding: 10px 5px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    bottom: 150px;
    z-index: 9999;
    background-color: $primary;

    p { 
        color: white;
        margin-bottom: 5px;
        font-family: 'Oswald', sans-serif;
        font-size: 12px;
    }
    .WpIcon {
        background-image: url('./cart.png');
        background-repeat: no-repeat;        
        background-size: 48px;
        cursor: pointer;
        display: inline-block;
        height: 48px;
        width: 48px;
        vertical-align: middle;
        margin-left: 5px;
    }

    .cartQty {
        display: inline-block;
        background-color: $secondary;
        border-radius: 100px;
        height: 25px;
        width: 25px;
        font-weight: bold;
        color: white;
        text-align: center;
    }
}