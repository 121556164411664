.Devoluciones {
    margin-bottom: 70px;

    button {
        margin-top: 20px;
    }
    ul {
        list-style: disc;
        padding-left: 15px;
    }
}