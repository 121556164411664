@import './../../variables.scss';

.ProductsContainer {
    padding: 20px 0;        
    .FamiliaList {
        width: 100%;
    }

    .PedidoInstructions {
        background-color: $secondary-light;
        color: $tertiary;
        padding: 10px;
        margin: 5px 0;
        min-height: 75px;
        .instruction,
        .iconContainer { 
            float: left; 
            margin-right: 15px;
        }
    }

    .icon {
        background-size: 48px;
        height: 48px;
        width: 48px;
    }
    .sale { background-image: url('./sale.png'); }
    .weight { background-image: url('./weight.png'); }

    .ProductFilterContainer { margin-bottom: 20px; }

    @media (max-width: 993px) {
        display: initial;
        .ProductListContainer,
        .FamiliasContainer { 
            float: none;
            margin: 15px 0;
            height: auto;
            width: 100%; 
        }
        
        .FamiliaList {
            width: 100%;
        }
    }
}

.ProductsError { margin-bottom: 30px; }
