.instructionsImgs {
    background-repeat: no-repeat;
    background-size: 50px;
    cursor: pointer;
    display: inline-block;
    background-size: 50px;
    width: 50px;
    height: 50px;
    vertical-align: middle;

    &.cart { 
        background-image: url('./cart.png'); 
        background-size: 40px;
        width: 40px;
        height: 40px;
    }
    &.discount { 
        background-image: url('./discount.png');
        width: 300px;
        height: 23px;
        background-size: 300px;}
    &.description { 
        background-image: url('./description.png');
        background-size: 150px 30px;
        width: 150px;
        height: 30px;
    }
    &.sendPedido { 
        background-image: url('./sendPedido.png'); 
        width: 100px;
        height: 50px;
        background-size: 100px;
    }
    &.weight { 
        background-image: url('./weight.png'); 
        background-size: 50px;
        width: 50px;
        height: 50px;
    }
    &.ingresar { 
        background-image: url('./ingresar.png'); 
        width: 65px;
        background-size: 65px 30px;
        height: 30px;
    }
}