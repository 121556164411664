@import './../../variables.scss';

.Home {
    padding-top: 50px;
    img { width: 100%; }
    .PresentationContainer {
        .MainText {
            font-family: 'Oswald', sans-serif;
            font-weight: 400;
            width: 400px;
            color: $tertiary;
        }

        .SectionTitle {
            margin-bottom: 10px;
        }
        .HighlightSection {
            color: $tertiary;
            overflow: hidden;
            
            .iconContainer {
                float: left;
                margin-right: 15px;
                
    
                .sale {
                    background-image: url('./sale.png');
                }

                .truck { background-image: url('./truck.png'); }
            }
            h4 {
                font-size: 14px;
                font-weight: 600;
                color: $success;
            }

            p {
                font-size: 12px;
                color: $tertiary;
            }
        }
    }    
}

@media (max-width: 780px) {
    .Home {
        .PresentationContainer {
            .MainText {
                margin-bottom: 20px;
                width: 100%;
            }
        }
    }
}