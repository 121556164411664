@import './../../variables.scss';
ul {
    list-style: none;
    padding: 0;
}

#FamiliaNav {
    background-color: white;
    padding: 0;

    .FamiliaItem,
    .SubfamiliaItem {
        background-color: white;
        border: none;
        a:hover { text-decoration: none; }
        button {
            background-color: $secondary-light;
            color: $tertiary;
            cursor: pointer;
            font-weight: 600;
            text-align: left;
            margin: 1px 0;
            padding: 10px;
            font-size: 13px;
            border: none;

            .ExpandSubfamilias { 
                color: $primary;
                float: right; 
                &:focus,
                &:hover {
                    color: $tertiary;
                }
            }
            &:focus,
            &:hover {
                background-color: $primary;
                color: white;
                outline: none;
            }
        }

        &.ofertas {
            button {    
                color: green;
                &:focus,
                &:hover {
                    background-color: $primary;
                    color: white;
                    outline: none;
                }
            }
        }
    }

    .SubfamiliaItem {
        .SubfamiliaBtn { 
            background-color: $tertiary;
            color: white;
            font-weight: 500;
            padding: 7px 20px;
            width: 100%;

            &:focus,
            &:hover {
                color: $tertiary;
            }
        }
    }

    .FamiliaBtn {
        padding: 5px 20px;
        width: 100%;
    }
}